<template>
  <div class="container-fluid overflow-hidden">
    <div class="row">
      <div class="col-12 pb-5 pt-5 d-block d-xl-none">
        <div class="row px-2 position-relative">
          <div class="col-1">
            <div
              class="step h-100"
              :class="step >= 1 ? 'active' : ''"
              data-index="1"
            >
              <span>Выберите регион и офис проката</span>
            </div>
          </div>
          <div class="col-11 col-xl-11">
            <div class="sidebar__city pt-4">
              <side-bar-city />
            </div>
          </div>
          <div class="col-1">
            <div
              class="step h-100 step_second"
              :class="step >= 2 ? 'active' : ''"
              data-index="2"
            >
              <span>Укажите даты проката</span>
            </div>
          </div>
          <div class="col-11 col-xl-11">
            <div class="sidebar__calendar pt-4 pb-2">
              <side-bar-calendar />
            </div>
          </div>
          <div class="col-1">
            <div
              class="step h-100 step_second step_last"
              :class="step === 3 ? 'active' : ''"
              data-index="3"
            >
              <span>Отсортировать каталог по доступности на даты проката</span>
            </div>
          </div>

          <div class="col-11 col-xl-11 pt-4">
            <button
              class="button button_accent mt-3"
              @click="changeDate"
              :disabled="step !== 3"
            >
              Отсортировать
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 text-center overflow-hidden">
        <h2 id="final">
          МОДЕЛЬНЫЙ РЯД
          <span class="accent"
            ><br />
            <span class="small">
              <template v-if="$route.query.from && $route.query.to">
                с {{ $moment($route.query.from).format("DD-MM-YYYY") }} по
                {{ $moment($route.query.to).format("DD-MM-YYYY") }}
                <br />
              </template>
              {{ getActiveSite.address }}
              <a :href="`tel:${getActiveSite.phone_2}`">{{
                getActiveSite.phone_2
              }}</a>
            </span>
          </span>
        </h2>
      </div>
      <div class="col-12 pt-4">
        <div class="row">
          <template v-if="getLoadingCars">
            <div
              v-for="i in 12"
              :key="i"
              class="col-12 col-lg-3 col-xxl-3 col-xxxl-2 col-md-6 py-3"
            >
              <div class="loading"></div>
            </div>
          </template>
          <template v-else>
            <catalog-item
              v-for="car in getCarsByPage"
              :key="car.id"
              :car="car"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CatalogItem from "@/components/components/Catalog/CatalogItem";
import SideBarCalendar from "@/components/layout/SideBar/SideBarCalendar";
import SideBarCity from "@/components/layout/SideBar/SideBarCity";

export default {
  name: "Catalog",
  components: { SideBarCalendar, CatalogItem, SideBarCity },
  mounted() {
    this.page = parseInt(this.$route.query?.page || 1);
  },
  computed: {
    ...mapGetters("config", [
      "getActiveCity",
      "getSite",
      "getDate",
      "getActiveSite",
    ]),
    ...mapGetters("car", ["getCars", "getLoadingCars"]),
    getCarsByPage() {
      return this.getCars;
    },
    step() {
      if (!this.getActiveCity.id || !this.getActiveSite) {
        return 1;
      } else if (!this.getDate || !this.getDate.start || !this.getDate.end) {
        return 2;
      }
      return 3;
    },
  },
  methods: {
    ...mapActions("car", ["loadFreeCars"]),
    async changeDate() {
      if (this.$route.name !== "Home" && this.$route.name !== "Catalog") {
        await this.$router.push({
          name: "Catalog",
          query: {
            from: this.getDate.start,
            to: this.getDate.end,
          },
        });
      } else {
        await this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            from: this.getDate.start,
            to: this.getDate.end,
          },
        });
      }
      await this.loadFreeCars({
        from: this.$route.query.from,
        to: this.$route.query.to,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.loading {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  min-height: 250px;
}
.small {
  font-size: em(12);
}
.step {
  width: 1px;
  background-color: #000;
  position: relative;
  top: 0px;
  //bottom: -20px;
  &::after {
    content: attr(data-index);
    position: absolute;
    color: #fff;
    top: 3px;
    left: -2px;
  }
  &::before {
    content: "";
    width: 30px;
    height: 30px;
    background-color: #000;
    border-radius: 10em;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
  span {
    position: absolute;
    left: 30px;
    top: 8px;
    font-size: em(12);
    width: 280px;
    color: #ff6600;
    font-weight: 500;
  }
  &.active {
    &::before {
      background-color: #ff6600;
    }
  }
  &_second {
    top: 0;
    bottom: 0;
    &::before {
      top: -5px;
    }
    &::after {
      top: -2px;
    }
    span {
      top: 0;
    }
  }
  &_last {
    background: transparent;
  }
}
</style>
