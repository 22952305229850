<template>
  <div
    class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3 col-xxxl-2 py-3 d-flex align-items-stretch"
  >
    <div
      class="catalog overflow-hidden position-relative"
      :class="{ 'catalog_not-free': !isFree }"
    >
      <h4 class="catalog__title">
        <a href="#" :to="getLink" @click.prevent="goToCar">{{ car.name }}</a>
      </h4>
      <div class="catalog__content">
        <div class="catalog__image mb-2" v-if="car.image">
          <a href="#" :to="getLink" @click.prevent="goToCar">
            <img :src="`${getFilePrefix}${car.image}`" :alt="car.name" />
          </a>
          <div class="catalog__free-info" v-if="!isFree">
            На указанные даты ({{
              $moment($route.query.from).format("DD.MM.YYYY")
            }}
            - {{ $moment($route.query.to).format("DD.MM.YYYY") }}) а/м
            предварительно не свободен. Оставьте заявку и/или свяжитесь с
            менеджером
            <a :href="`tel:${activeSite.phone_2}`">{{ activeSite.phone_2 }}</a>
          </div>
        </div>
        <div class="catalog__info pb-sm-4">
          <div class="row d-flex align-items-center">
            <div
              class="col-12 col-sm-4 d-flex align-items-center catalog__item"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.34 9.33L20.34 8.33C19.97 8.15 19.51 8.29 19.33 8.66C19.14 9.04 19.29 9.49 19.66 9.67L21.25 10.46V15.25L17.5 15.26V5C17.5 3 16.16 2 14.5 2H6.5C4.84 2 3.5 3 3.5 5V21.25H2C1.59 21.25 1.25 21.59 1.25 22C1.25 22.41 1.59 22.75 2 22.75H19C19.41 22.75 19.75 22.41 19.75 22C19.75 21.59 19.41 21.25 19 21.25H17.5V16.76L22 16.75C22.42 16.75 22.75 16.41 22.75 16V10C22.75 9.72 22.59 9.46 22.34 9.33ZM6 6.89C6 5.5 6.85 5 7.89 5H13.12C14.15 5 15 5.5 15 6.89V8.12C15 9.5 14.15 10 13.11 10H7.89C6.85 10 6 9.5 6 8.11V6.89ZM6.5 12.25H9.5C9.91 12.25 10.25 12.59 10.25 13C10.25 13.41 9.91 13.75 9.5 13.75H6.5C6.09 13.75 5.75 13.41 5.75 13C5.75 12.59 6.09 12.25 6.5 12.25Z"
                  fill="#90A3BF"
                />
              </svg>
              <span>{{ car.fuel_consumption }}</span>
            </div>
            <div
              class="col-12 col-sm-4 d-flex align-items-center catalog__item"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.53 2 12 2Z"
                  fill="#90A3BF"
                />
                <rect x="4" y="4" width="16" height="16" rx="8" fill="white" />
                <path
                  d="M12 6C8.688 6 6 8.688 6 12C6 15.312 8.688 18 12 18C15.312 18 18 15.312 18 12C18 8.688 15.318 6 12 6Z"
                  fill="#90A3BF"
                />
                <rect x="8" y="8" width="8" height="8" rx="4" fill="white" />
                <rect x="11" y="17" width="2" height="4" fill="#90A3BF" />
                <rect x="17" y="11" width="4" height="2" fill="#90A3BF" />
                <rect x="3" y="11" width="4" height="2" fill="#90A3BF" />
              </svg>

              <span class="text-uppercase">{{ car.transmission }}</span>
            </div>
            <div
              class="col-12 col-sm-4 d-flex align-items-center catalog__item"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z"
                  fill="#90A3BF"
                />
                <path
                  d="M14.08 14.1499C11.29 12.2899 6.73996 12.2899 3.92996 14.1499C2.65996 14.9999 1.95996 16.1499 1.95996 17.3799C1.95996 18.6099 2.65996 19.7499 3.91996 20.5899C5.31996 21.5299 7.15996 21.9999 8.99996 21.9999C10.84 21.9999 12.68 21.5299 14.08 20.5899C15.34 19.7399 16.04 18.5999 16.04 17.3599C16.03 16.1299 15.34 14.9899 14.08 14.1499Z"
                  fill="#90A3BF"
                />
                <path
                  d="M19.9899 7.3401C20.1499 9.2801 18.7699 10.9801 16.8599 11.2101C16.8499 11.2101 16.8499 11.2101 16.8399 11.2101H16.8099C16.7499 11.2101 16.6899 11.2101 16.6399 11.2301C15.6699 11.2801 14.7799 10.9701 14.1099 10.4001C15.1399 9.4801 15.7299 8.1001 15.6099 6.6001C15.5399 5.7901 15.2599 5.0501 14.8399 4.4201C15.2199 4.2301 15.6599 4.1101 16.1099 4.0701C18.0699 3.9001 19.8199 5.3601 19.9899 7.3401Z"
                  fill="#90A3BF"
                />
                <path
                  d="M21.9902 16.5899C21.9102 17.5599 21.2902 18.3999 20.2502 18.9699C19.2502 19.5199 17.9902 19.7799 16.7402 19.7499C17.4602 19.0999 17.8802 18.2899 17.9602 17.4299C18.0602 16.1899 17.4702 14.9999 16.2902 14.0499C15.6202 13.5199 14.8402 13.0999 13.9902 12.7899C16.2002 12.1499 18.9802 12.5799 20.6902 13.9599C21.6102 14.6999 22.0802 15.6299 21.9902 16.5899Z"
                  fill="#90A3BF"
                />
              </svg>

              <span>{{ car.air_conditioning }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="catalog__bottom">
        <div class="row d-flex align-items-center">
          <div class="col-12 col-lg-6 col-xxxl-4 position-relative">
            <div class="catalog__pricing" v-if="car.rates.length">
              <div class="catalog__price catalog__price_default">
                <span>от</span>
                <b> {{ getMinPrice.summ.toLocaleString() }} ₽/ </b>
                <span>сут.</span>
              </div>
            </div>
            <div class="catalog__other">
              <div
                class="catalog__price d-flex justify-content-between"
                v-for="(price, index) in getUnionPrice"
                :key="index"
              >
                <div>
                  <span>
                    <template v-if="price.start_day && price.end_day"
                      >{{ price.start_day }} -
                      {{ price.end_day }} сут.</template
                    >
                    <template v-else>от {{ price.start_day }} сут.</template>
                  </span>
                </div>
                <div>
                  <b>{{ price.summ.toLocaleString() }} ₽/</b>
                  <span>сут.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 offset-xxxl-2">
            <button class="button button_full" @click.prevent="goToCar">
              Заказать
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { find, minBy, unionBy } from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "CatalogItem",
  props: {
    car: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("car", ["getFreeById"]),
    ...mapGetters("config", ["getSite", "getActiveCity"]),
    isFree: (vm) =>
      !vm.$route.query.from && !vm.$route.query.to
        ? true
        : vm.getFreeById(vm.car.id),
    getFilePrefix() {
      return process.env.VUE_APP_FILE_PATH;
    },
    getMinPrice() {
      return minBy(this.car.rates, (rate) => rate.summ) || {};
    },
    getUnionPrice() {
      return unionBy(this.car.rates, "name") || [];
    },
    activeSite() {
      if (this.getActiveCity.sites) {
        return (
          find(this.getActiveCity.sites, (site) => site.id === this.getSite) ||
          {}
        );
      }
      return {};
    },
    getLink() {
      const router = {
        name: this.$route.name,
        query: {},
      };
      router.query.car = this.car.id;
      return router;
    },
  },
  methods: {
    goToCar() {
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, car: this.car.id },
      });
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.catalog {
  background: #ffffff;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  @include media(768) {
    padding: 10px;
  }
  &_not-free {
    .catalog__image {
      filter: grayscale(100%);
    }
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: em(20);
    color: #1a202c;
    text-transform: uppercase;
    @include media(768) {
      max-width: 250px;
    }

    a {
      color: #1a202c;
      text-decoration: none;
    }
  }

  &__mark {
    text-transform: uppercase;
    font-weight: 700;
    font-size: em(14);
    line-height: 18px;
    color: #90a3bf;
  }

  &__image {
    position: relative;
    @include media(768) {
      position: static;
    }
    img {
      max-width: 100%;
      @include media(768) {
        max-width: 250px;
      }
    }
  }

  &__item {
    @include media(768) {
      padding-bottom: 10px;
    }
    span {
      padding-left: 7px;
      display: block;
      font-size: 0.8rem;
    }
    svg {
      min-width: 24px;
    }
  }
  &__bottom {
    @include media(768) {
      position: absolute;
      top: 10px;
      right: 10px;
      min-width: 200px;
      overflow: hidden;
    }
  }

  &__content {
    position: relative;
    transition: all 0.25s;
    z-index: 3;
    @include media(768) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      //align-items: center;
    }
  }

  &__price {
    @include media(768) {
      text-align: right;
    }
    span {
      font-size: em(10);
      color: #90a3bf;
    }

    b {
      font-size: em(14);
      font-weight: bold;
    }
  }

  &__other {
    position: absolute;
    bottom: -150px;
    transition: all 0.25s;
    left: 0;
    right: 0;
    max-width: 150px;
    @include media(768) {
      display: none;
    }
  }
  &__free-info {
    background-color: rgba(lighten(grey, 40%), 0.9);
    padding: 5px;
    border-radius: 5px;
    font-size: em(10);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    @include media(768) {
      position: relative;
      margin-left: -45px;
    }
    a {
      text-decoration: none;
    }
  }

  &:hover {
    .catalog {
      &__pricing {
        display: none;
      }

      &__other {
        bottom: -30px;
      }

      &__content {
        transform: translateY(-30px);
      }
    }
  }
}
.button {
  @include media(768) {
    display: none;
  }
}
</style>
