<template>
  <div class="container-fluid slider__wrap">
    <div class="row">
      <div class="col-12 col-xl-9">
        <div class="slider" v-if="slider.length">
          <vue-slick-carousel v-bind="slickOptions">
            <div
              class="slider__item"
              v-for="(slide, index) in slider"
              :key="index"
              :style="{
                backgroundColor: slide.color || '#613EEA',
                backgroundImage: `url(${getFilePrefix}${
                  isMobile ? slide.mobile_image : slide.image
                })`,
              }"
            >
              <div class="slider__content">
                <div class="slider__top">
                  <div class="slider__title" v-html="slide.description" />
                </div>
                <div class="slider__bottom">
                  <router-link
                    :to="slide.link"
                    v-if="slide.link"
                    class="button button_accent"
                  >
                    {{ slide.buttonText || "Подробнее" }}
                  </router-link>
                </div>
              </div>
            </div>
          </vue-slick-carousel>
        </div>
      </div>
      <div class="col-12 col-xl-3 d-block d-lg-none d-xl-block">
        <info-sidebar class="info" :info="getMainStock" />
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import InfoSidebar from "@/components/pages/InfoSidebar";
import { mapGetters } from "vuex";

export default {
  name: "IndexSlider",
  components: {
    InfoSidebar,
    VueSlickCarousel,
  },
  data() {
    return {
      slider: [],
      slickOptions: {
        arrows: false,
        slidesToShow: 1,
        dots: true,
        autoplay: true,
      },
    };
  },
  computed: {
    ...mapGetters("content", ["getMainStock"]),
    getFilePrefix() {
      return process.env.VUE_APP_FILE_PATH;
    },
    isMobile() {
      return window.innerWidth < 769;
    },
  },
  mounted() {
    if (!this.slider.length) {
      this.loadSlider();
    }
  },
  methods: {
    async loadSlider() {
      try {
        const response = await this.$axios.api.banners({});
        this.slider = response.data;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.slider {
  &__wrap {
    padding-bottom: 60px;
    @include media(992) {
      padding-bottom: 20px;
    }
  }

  &::v-deep {
    .slick-dots {
      bottom: 15px;
      right: 20px;
      text-align: right;

      li {
        width: 20px;
        height: 20px;
      }

      button {
        height: 20px;
        width: 20px;

        &:before {
          width: 20px;
          height: 20px;
          font-size: 20px;
          line-height: 20px;
          color: #d9d9d9;
        }
      }
      @include media(1200) {
        li {
          width: 30px;
          height: 30px;
        }
        button {
          width: 30px;
          height: 30px;
          &::before {
            width: 30px;
            height: 30px;
            font-size: 30px;
          }
        }
      }
    }
  }

  &__item {
    border-radius: 10px;
    overflow: hidden;
    //background-repeat: no-repeat;
    //background-position: right bottom;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: stretch;
    height: 100%;
  }

  &__content {
    padding: 40px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-height: 450px;
    @include media(1200) {
      min-height: 350px;
      padding: 20px;
    }
    @media screen and (min-width: 1921px) {
      aspect-ratio: 5/2;
    }
  }

  &__title {
    max-width: 700px;
    font-size: em(45);
    font-weight: 700;
    @include media(768) {
      font-size: em(20);
    }
  }
}

.button {
  width: auto;
  max-width: 200px;
}
.info {
  max-height: 100%;
  height: 100%;
}
</style>
