<template>
  <div class="container-fluid questions">
    <div class="row">
      <div class="col-12 col-lg-4 col-xl-6">
        <h2>Часто задаваемые вопросы</h2>
        <img src="/BG.png" alt="" />
      </div>
      <div class="col-12 col-lg-8 col-xl-6">
        <div
          class="questions__item"
          v-for="question in questions"
          :key="question.id"
        >
          <h3 class="questions__title">{{ question.title }}</h3>
          <hr class="questions__line" />
          <div class="questions__text" v-html="question.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Quetions",
  data() {
    return {
      questions: [],
    };
  },
  mounted() {
    this.loadQuestion();
  },
  methods: {
    async loadQuestion() {
      try {
        const response = await this.$axios.api.faq({});
        this.questions = response.data;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.questions {
  padding-top: 50px;

  img {
    max-width: 100%;
    vertical-align: top;
  }

  h2 {
    max-width: 450px;
  }

  &__item {
    padding-bottom: 40px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__title {
    font-weight: 600;
    font-size: em(20);
    color: #000000;
    margin-bottom: 15px;
  }

  &__line {
    max-width: 103px;
    border-color: #ff4c0d;
    border-width: 3px;
    opacity: 1;
  }

  &__text {
    font-size: em(14);
    color: #333333;
    max-width: 660px;
  }
}
</style>
