<template>
  <router-link
    :to="{ name: 'EventDetail', params: { id: info.id } }"
    tag="div"
    class="info position-relative"
    :style="{
      backgroundColor: '#A0A0A0',
      backgroundImage: `url(${getFilePrefix}${info.preview_picture})`,
    }"
  >
    <div class="info__top">
      <h3 class="info__title">
        <div>{{ info.title }}</div>
      </h3>
      <!-- <div class="info__desc" v-html="info.text" />-->
    </div>
    <div class="info__bottom">
      <router-link
        :to="info.link"
        v-if="info.link"
        class="button button_accent button_event"
        >{{ info.buttonText || "Подробнее" }}
      </router-link>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "InfoSidebar",
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getFilePrefix() {
      return process.env.VUE_APP_FILE_PATH;
    },
  },
};
</script>

<style scoped lang="scss">
.info {
  min-height: 450px;
  background-size: cover !important;
  background: #a0a0a0 no-repeat center right;
  border-radius: 10px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;
  @include media(992) {
    min-height: 190px;
    padding: 20px;
  }

  &__top,
  &__bottom {
    position: relative;
    z-index: 2;
  }

  &__title {
    /*color: #fff;*/
    font-size: em(32);
    font-weight: 700;
    @include media(768) {
      font-size: em(20);
    }

    a {
      color: #fff;
      text-decoration: none;
    }
  }

  &__desc {
    padding-top: 12px;
    font-weight: 500;
    color: #fff;
  }

  &__image {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;

    img {
      max-width: 100%;
    }
  }

  .button {
    width: auto;
    max-width: 200px;
  }
}
</style>
